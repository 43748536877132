<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Perform each of the calculations described below:</p>

      <p class="mb-2">
        a) A box has a length of
        <number-value :value="length" unit="\text{in,}" />
        a width of
        <number-value :value="width" unit="\text{in,}" />
        and a height of
        <number-value :value="height" unit="\text{in.}" />
        What is the volume of the box in
        <stemble-latex content="$\text{in}^3?$" />
      </p>
      <calculation-input
        v-model="inputs.volumeInInchesCubed"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{in}^3$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What is the volume of the same box in
        <stemble-latex content="$\text{cm}^3?$" />
      </p>
      <calculation-input
        v-model="inputs.volumeInCmCubed"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{cm}^3$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'UnitConversions1',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        volumeInInchesCubed: null,
        volumeInCmCubed: null,
      },
    };
  },
  computed: {
    length() {
      return this.taskState.getValueBySymbol('length').content;
    },
    width() {
      return this.taskState.getValueBySymbol('width').content;
    },
    height() {
      return this.taskState.getValueBySymbol('height').content;
    },
  },
};
</script>
